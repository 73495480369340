.page-container {
    background-color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.form-container {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 12px;
    position: absolute;
    top: 30%;
    left: 36%;
    padding: 40px;
    padding: 40px;


}

.form label .input-box {

    display: block;
    flex-direction: column;
    align-items: center;
    margin: 10px;

}

.input-box {
    border-color: none;
    border-style: none;
    width: 300px;
    height: 40px;
    border-radius: 12px;
    margin-bottom: 10px;
}

.button-sign {
    background-color: var(--icons);
    border-color: none;
    border-style: none;
    border-radius: 12px;
    width: 100px;
    height: 40px;
    margin: 30px 30px 0 30px;
    font-size: 16px;



}

.button-register {

    background-color: var(--icons);
    border: none;
    border-style: none;
    border-radius: 12px;
    width: 100px;
    height: 40px;
    margin: 30px 30px 0 100px;
    font-size: 16px;
    color: white;

}

.button-link {
    text-decoration: none;
    color: white;
    background-color: var(--icons);
    border-color: none;
    border-style: none;
    border-radius: 12px;
    font-size: 16px;
}