.page-intro-container {
  height: 100vh;
  margin: 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../images/BTM Hero.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--white);
  position: relative;
  padding: 24rem 0 21rem 0;

}

/* Makes the image darker */
.page-intro-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  z-index: 1; /* Position overlay above the image */
} 


/* Ensure content is above the overlay */
.text-container,
.items-container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


/* .intro{
  display:grid;
  grid-template-columns:  ;
} */
 
.text-container {
  padding: 0 50px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  align-items: center; 
  color: #fff;
}

.items-container   {
  padding: 0 30px;
  /* height: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* margin-bottom: 80px; */
  align-items: center;  
}
 
.intro-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--white);
  padding-bottom: 10px;
  
}
 
.intro-text {
  font-size: 1.4rem;
  font-weight: 300;
  width:80%;
  color:  var(--white);
  padding-top: 35px;
  opacity: 1;
  /* animation: typing 3s steps(20, end); */
  /* animation: reveal 2s forwards; */
  /* animation: fadeInUp 1s 0.5s forwards; */
}

.Slogan{
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--white);
}

 .Slogan{
  overflow: hidden;
  white-space: nowrap;
  /* animation: typing 3s steps(30, end); */
  /* animation: fadeInUp 1s forwards; */
}
/* Typing effect */
@keyframes typing {
  from{width: 0}
  to {width: 100%}
}

/* Text Reveal effect */
@keyframes reveal {
  from {opacity: 0}
  to {opacity: 1};
}

/* Text fade effect */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(-20px);
  }
}

.talent{
  font-weight: 500;
}

.GetInTouch:hover{
  background-color: var(--primary);
  font-weight: 500;
  cursor: pointer;
}

.arrow-container{
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  margin-top: 6.5rem;
}

.intro-arrow{
  z-index: 10;
  box-sizing: border-box;
  height: 3rem;
  width: 3rem;
  border-style: solid;
  border-color: white;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.intro-arrow:hover{
  border-bottom-width: 4px;
  border-right-width: 4px;
  cursor: pointer;
}

.items-container button{
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  border: none;
  margin: 2rem;
  border-radius: 0.6rem;
  background-color: var(--icons);
  color: var(--white);
}
 
.btn-row {
  padding: 10px 0;
}
 
.btn {
  padding: 10px 25px;
  border: none;
  background-color: var(--primary);
  border-radius: 5px;
  color: var(--dark);
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  height: 45px;
}
 
.text-container .talent {
  color: var(--icons);
}
 
.text.btn:hover {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
}

.image-container {  
  width: 100%;
  height: 100%;
  /* background: url("../images/Logo_btm1.png") */
  /* no-repeat center / contain; */
  animation: introImage 2s ease-in-out infinite alternate-reverse;
}


/* @keyframes introImage {
  from {
    transform: translateY(12px);
  }
 
  to {
    transform: translateY(-12px);
  }
} */

@media (max-width: 320px) {
  .page-intro-container{
    height: auto;
  }
  .intro-title{
   font-size: larger;
  }
  
  .intro-text{
    font-size: smaller;
  }

  .text-container{
    margin-top: 100px;
  }

  .Slogan{
    white-space: wrap;
    overflow: auto;
  }
  
  }
 
  @media (min-width: 321px)and (max-width:550px){

    .intro-title{
      font-size: 2rem;
    }
  .intro-text{
    padding-top: 20px;
    font-size: 1rem;
  }

  .page-intro-container{
    padding: 24rem 0 29rem 0;
  }
  .items-container button{
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
    
  }

  .text-container{
    margin-top: 100px;
  }

  .Slogan{
    font-size: 0.8rem;
  }
  }

  @media (min-width: 551px) and (max-width: 876px) {
    .page-intro-container {
      padding: 8rem 0 4rem 0;
    }
  
    .Slogan{ 
      font-size: medium;
  
    }
  
    .intro-text{ 
      font-size: 1rem; 
      padding-top: 17px;
    
    }
   
  .image-container{
    height: 120px;
    width: 120px;
    margin:-20px auto;
  
  }
  
  .items-container{ 
  
    display: flex; 
    flex-direction:column;
  }
  
  .items-container button{
    padding: 0.8rem;
    font-size: 0.8rem;
    
  }
  
  }
@media (min-width: 877px)and(max-width: 1250px) {


  .page-intro-container {
    /* height: 100%; */
    /* margin: 30px;
    padding: 10px 0; */
  }


  .text-container {
    padding: 20px;
   margin-top: 100px;
    align-items: center;
    text-align: left;
  }

  .Slogan{
    font-size: 1.6rem;
  }
 
  .btn {
    font-size: 0.8rem;
    padding: 5px 10px;
    height: 30px;
  }
 
  .image-container {
    height: 90%;
    margin-top: 70px;
  }

 


}

 




