.sub-container{
    border-radius: 15px;
    height: 310px;
    width: auto;
    display: flex;
    background-color: var(--secondary);
    margin: 0px 20px 0px;
    padding: 10px 10px;
    align-items: center;
    justify-content: center;
    border-top: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    margin-bottom: 20px;
}

.subservice-card .card-title {
    top: 10px;
    position: relative;
    bottom: 100px;
    color: var(--white);
}

.subservice-card .card-icon {
    position: relative;
    bottom: 70px;
}

.subservice-card .card-text {
    position: relative;
    font-size: 14px;
    top: 60px;
    text-align: center;
    color: var(--white);
}

.subservice-card {
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    background-color: var(--secondary);
    padding: 20px;
    width: 280px;
    height: 280px;
    border: 1px solid transparent;
    margin: 10px;
}

.subservice-card i{
    color: var(--icons);
}
 
.software-development{
    visibility: visible;
    display: flex;
}
 
.data-and-AI{
    visibility: visible;
    display: flex;
}
 
.proof-of-concept{
    visibility: visible;
    display: flex;
}
 
.research-and-development {
    visibility: visible;
    display: flex;
}
 
@media (max-width: 1100px) {
 
    .software-development{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .data-and-AI{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .proof-of-concept{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .research-and-development {
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
}
 
@media (max-width: 800px) {
    .software-development{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .data-and-AI{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .proof-of-concept{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .research-and-development {
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
    .ai-arrow i {
        color: var(--primary);
        position: relative;
        left: 18%;
        font-size: 2rem;
    }
   
    .sd-arrow i {
        color: var(--primary);
        position: relative;
        left: 33.5%;
        font-size: 2rem;
    }
   
    .poc-arrow i {
        color: var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
    }
   
    .rd-arrow i {
        color: var(--primary);
        position: relative;
        left: 65%;
        font-size: 2rem;
    }
   
    .ux-arrow i {
        color: var(--primary);
        position: relative;
        left: 142%;
        font-size: 2rem;
    }
}
 
@media (max-width: 400px) {
    .software-development{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .data-and-AI{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .proof-of-concept{
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
   
    .research-and-development {
        visibility: visible;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 320px) {

 
.subservice-card{
    width: auto;
    height:auto;

}

.sub-container{
    height: 500px;
    width: auto;

}


.card-title{
    margin: -50px 0px -30px;

}



  
}
