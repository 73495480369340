.about-container {
  border-radius: 25px;
  margin: 15px 150px;
  display: flex;
  align-items: center;
  /* height: 350px; */
  height: auto;
  background-color: #f7f7f7;
}

.slide-container {
  top: 12px;
  display: flex;
  align-items: center;
  height: auto;
  object-fit: none;
}
 
.about-image-container {
  width: 50%;
  height: 300px;
  padding: 30px;
}
 
.about-image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background: url("../images/img1.jpeg")
  no-repeat center / cover;
}

.slide-image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
  align-items:center;
}

.slide-image img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
 
.about-text-container {
  padding: 10px;
  /* height: 100%; */
  height: auto;
  width: 50%;
}
 
.about-title {
  padding-top: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--dark);
  font-size: 2rem;
  color: var(--dark);
  margin-bottom: 10px;
}
 
.about-description {
  font-size: 1rem;
  color: var(--dark);
  font-style: italic;
}
 
.about h2 {
  color: var(--dark);
}

.about-text-container p:last-child {
  color: var(--dark);
}
 
.list {
  list-style: none;
}
 
.list li::before {
  font-family: "Font Awesome 5 Free";
  font-size: 19px;
  font-weight: 400;
  content: "\f058";
  width: 20px;
  height: 20px;
  color: var(--primary);
  padding-right: 5px;
}
 
.list li {
  padding: 10px 0;
  color: var(--dark);
  font-weight: 500;
}
 
@media (max-width: 1100px) {
  .about-container {
    margin: 15px 30px;
  }
 
  .about-title {
    font-size: 1.8rem;
  }
 
  .about-description,
  .list li {
    font-size: 0.9rem;
  }
}
 
@media (max-width: 800px) {
  .about-container {
    height: auto;
    flex-direction: column;
    margin: 15px 25px;
  }

  .slide-container {
    height: auto;
    flex-direction: column;
    margin: 5px 15px;
  }
 
  .about-image-container,
  .about-text-container {
    width: 100%;
  }
 
  .about-image-container {
    /* padding: 15px; */
    padding: 15px;
    padding-bottom: 0;
  }
 
  .about-text-container {
    padding: 15px;
    margin-top: 10px;
  }
}
 
@media (max-width: 400px) {
  .about-image-container {
    height: 0;
  }

  .about-image {
    display: inline-block;
    visibility: collapse;
  }

  .about h2{
    text-align: center;
    justify-content: center;
  }

  .about-text-container {
    padding-top: 0;
  }
}