.navbar-internal{
    background-color: #002646;
    color: white;
    display: block;
    padding: 10px;
    margin: 0;
    /* height: 40px; */
}

.nav-home{
  float: right;
  padding: 20px;
  text-decoration: none;
  color: white;
  font-size: 18px;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
 
}

.nav-brand {
  text-decoration: none;
  color: white;
  
}











